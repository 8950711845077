import "../../Styles/gis.scss";
import { useEffect } from "react";
import { useState } from "react";
import $ from "jquery";
// openlayers
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import WebGLPointsLayer from "ol/layer/WebGLPoints";
import VectorSource from "ol/source/Vector";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
import { useRef } from "react";
import XYZ from "ol/source/XYZ";
import { Cluster, OSM } from "ol/source";
import Feature from "ol/Feature";
import { Point } from "ol/geom";
import RippleLoading from "../Util/RippleLoading";
import { Text } from "ol/style";
import WaveLoading from "../Util/WaveLoading";
import GeoJSON from "ol/format/GeoJSON";
import Graticule from "ol/layer/Graticule";
import Basemaps from "./Basemaps";
import RightPanel from "./RightPanel";
import {
  ScaleLine,
  ZoomToExtent,
  defaults as defaultControls,
} from "ol/control";
import myData from "../../assets/data/data";
import Popup from "./Popup";

export default function GIS(props) {
  let template = {
    Title: "",
    Category: "Thematic",
    Description: "",
    Thumbnail: "",
    Dataset: "",
    Keywords: "",
    Owner: "",
    Type: "",
    Data: [],
    Status: "",
  };
  const [filter, setFilter] = useState(null);
  const [loading, setLoading] = useState(false);
  const [waveLoading, setWaveLoading] = useState(false);
  const [showing, setShowing] = useState([]);
  const [visualization, setvisualization] = useState("Normal");
  const [subcounty, setSubCounty] = useState(
    new VectorLayer({ title: "Sub Counties" })
  );
  const [selected, setSelected] = useState(0);
  const [ward, setWard] = useState(new VectorLayer({ title: "Wards" }));
  const [data, setData] = useState([]);
  const [basemap, setBasemap] = useState(new TileLayer({ title: "Basemap" }));
  const [graticule, setGraticule] = useState(
    new Graticule({
      strokeStyle: new Stroke({
        color: "rgba(0,0,0,0.5)",
        width: 2,
        lineDash: [0.5, 8],
      }),
      showLabels: true,
      wrapX: false,
      title: "Grid"
    })
  );
  const [popup, setPopup] = useState(null);
  const [styleSelector, setStyleSelector] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [querySelector, setQuerySelector] = useState(null);
  const [baseSelector, setBaseSelector] = useState(null);
  const [extent, setExtent] = useState([
    4185121.788385366, -342263.56009544665, 4348867.3136286605,
    -6199.27329767576,
  ]);
  //set initial state
  const [map, setMap] = useState(null);
  const mapElement = useRef();
  const mapRef = useRef();
  mapRef.current = map;

  useEffect(() => {
    basemap.setSource(
      new XYZ({
        url: myData[0].url,
        crossOrigin: "anonymous",
      })
    );
    const initialMap = new Map({
      target: mapElement.current,
      layers: [basemap, subcounty, ward, graticule],
      view: new View({
        projection: "EPSG:4326",
        center: [36.45, -0.1],
        zoom: 12,
        maxZoom: 20,
      }),
      controls: defaultControls().extend([
        new ZoomToExtent({
          extent: [34.36168, 0.41839, 35.06887, 1.14702],
        }),
        new ScaleLine({
          units: "metric",
          bar: false,
          text: "Scale",
        }),
      ]),
    });

    initialMap.forEachFeatureAtPixel((e) => {
      console.log(e);
    });

    initialMap.on("click", function (ev) {
      initialMap.forEachFeatureAtPixel(ev.pixel, function (feature) {
        setPopup(feature.values_);
        return true;
      });
    });

    setMap(initialMap);
  }, []);

  useEffect(() => {
    if (map) {
      map.updateSize();
    }
  }, [props.fullscreen]);

  useEffect(async () => {
    if (map) {
      initializeData();
    }
  }, [map]);

  function initializeData() {
    loadMapData();
    LoadData("KisiiSubcounties", subcounty, styleSC);
    LoadData("KisiiWards", ward, styleWRD);
  }

  async function LoadData(url, layer, style) {
    try {
      var response = await $.ajax({
        url: getUrl(url),
        dataType: "json",
        success: {},
        error: function (xhr) {},
      });
      $.when(response).done(function (data) {
        layer.setSource(
          new VectorSource({
            features: new GeoJSON({}).readFeatures(data),
          })
        );
        layer.setStyle(style);
      });
    } catch (error) {}
  }

  function styleSC(feature) {
    const style = new Style({
      stroke: new Stroke({
        color: "blue",
        width: 5,
      }),
      text: new Text({
        text: feature.values_.subcounty,
        fill: new Fill({
          color: "blue",
          font: "30px sans-serif",
        }),
      }),
    });

    return style;
  }

  function styleWRD(feature) {
    const style = new Style({
      stroke: new Stroke({
        color: "red",
        width: 2,
      }),
      text: new Text({
        text: feature.values_.ward,
        fill: new Fill({
          color: "red",
          font: "30px sans-serif",
        }),
      }),
    });

    return style;
  }

  useEffect(() => {
    if (map) {
      if (visualization === "Normal") {
        removeByTitle("farmers");
        addToMap(data, farmerStyle);
      }
      if (visualization === "Gender") {
        removeByTitle("farmers");
        addToMap(data, genderStyle);
      }
      if (visualization === "Value Chain") {
        removeByTitle("farmers");
        addToMap(data, otherStyles("ValueChain"));
      }
      if (visualization === "Age Group") {
        removeByTitle("farmers");
        addToMap(data, otherStyles("AgeGroup"));
      }
      if (visualization === "Farmer Groups") {
        removeByTitle("farmers");
        addToMap(data, otherStyles("Group"));
      }
      if (visualization === "Farming Type") {
        removeByTitle("farmers");
        addToMap(data, otherStyles("FarmingType"));
      }
      if (visualization === "Sub County") {
        removeByTitle("farmers");
        addToMap(data, otherStyles("SubCounty"));
      }
      if (visualization === "Ward") {
        removeByTitle("farmers");
        addToMap(data, otherStyles("Ward"));
      }
    }
  }, [visualization]);

  function removeByTitle(title) {
    map
      .getLayers()
      .getArray()
      .filter((layer) => layer.get("title") === title)
      .forEach((layer) => map.removeLayer(layer));
  }

  let genderStyle = {
    symbol: {
      symbolType: "circle",
      size: 10,
      color: [
        "interpolate",
        ["linear"],
        ["get", "Gender"],
        "Male",
        "blue",
        "Female",
        "purple",
      ],
      rotateWithView: false,
    },
  };

  function otherStyles(v) {
    const unique = [...new Set(data.map((item) => item[v]))];
    let color = ["match", ["get", v]];

    if (unique.length <= 1 || unique.length > 500) {
      return {
        symbol: {
          symbolType: "circle",
          size: 10,
          color: getColor(),
          rotateWithView: false,
        },
      };
    } else {
      unique.map((item) => {
        if (item !== null) {
          color.push(item.toString());
          color.push(getColor());
        }
      });
      color.push(getColor());
      console.log(unique);
      console.log(color);
      return {
        symbol: {
          symbolType: "circle",
          size: 10,
          color: color,
          rotateWithView: false,
        },
      };
    }
  }

  let farmerStyle = {
    symbol: {
      symbolType: "circle",
      size: 10,
      color: "#064985",
      rotateWithView: false,
    },
  };

  function getColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  function getUrl(url) {
    return `/api/geoserver/kisii/wfs?request=GetFeature&version=1.0.0&typeName=kisii:${url}&outputFormat=json`;
  }

  const loadMapData = () => {
    setLoading(true);

    fetch("/api/farmeraddress/mapdata")
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data?.length > 0) {
          setData(data);
          addToMap(data, farmerStyle);
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  function addToMap(data, style) {
    setWaveLoading(true);
    let features = new Array(data.length);
    data.map((item, i) => {
      features[i] = new Feature({
        geometry: new Point([
          parseFloat(item.Longitude),
          parseFloat(item.Latitude),
        ]),
      });
      const obj = Object.entries(item);
      obj.map((dt) => {
        features[i].values_[dt[0]] = dt[1];
      });
    });

    const vs = new VectorSource({
      features: features,
      format: new GeoJSON(),
    });
    const f = new WebGLPointsLayer({
      title: "farmers",
      style: style,
      disableHitDetection: false,
      source: vs,
    });
    map.addLayer(f);
    map.getView().fit(vs.getExtent(), {
      padding: [100, 100, 100, 100],
    });
    setWaveLoading(false);
  }

  const saveMap = () => {
    map.once("rendercomplete", function () {
      const mapCanvas = document.createElement("canvas");
      const size = map.getSize();
      mapCanvas.width = size[0];
      mapCanvas.height = size[1];
      const mapContext = mapCanvas.getContext("2d");
      Array.prototype.forEach.call(
        map.getViewport().querySelectorAll(".ol-layer canvas, canvas.ol-layer"),
        function (canvas) {
          if (canvas.width > 0) {
            const opacity =
              canvas.parentNode.style.opacity || canvas.style.opacity;
            mapContext.globalAlpha = opacity === "" ? 1 : Number(opacity);
            let matrix;
            const transform = canvas.style.transform;
            if (transform) {
              // Get the transform parameters from the style's transform matrix
              matrix = transform
                .match(/^matrix\(([^\(]*)\)$/)[1]
                .split(",")
                .map(Number);
            } else {
              matrix = [
                parseFloat(canvas.style.width) / canvas.width,
                0,
                0,
                parseFloat(canvas.style.height) / canvas.height,
                0,
                0,
              ];
            }
            // Apply the transform to the export map context
            CanvasRenderingContext2D.prototype.setTransform.apply(
              mapContext,
              matrix
            );
            const backgroundColor = canvas.parentNode.style.backgroundColor;
            if (backgroundColor) {
              mapContext.fillStyle = backgroundColor;
              mapContext.fillRect(0, 0, canvas.width, canvas.height);
            }
            mapContext.drawImage(canvas, 0, 0);
          }
        }
      );
      mapContext.globalAlpha = 1;
      mapContext.setTransform(1, 0, 0, 1, 0, 0);

      var link = document.createElement("a");
      link.setAttribute("href", mapCanvas.toDataURL());
      link.setAttribute("download", "map.png");
      document.body.appendChild(link);
      link.click();
    });
    map.renderSync();
  };

  const saveData = () => {
    if (data.length > 0) {
      let rows = [];
      rows.push(Object.keys(data[0]));
      data.map((item) => {
        rows.push(Object.values(item));
      });
      let csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");

      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "mapdata.csv");
      document.body.appendChild(link);
      link.click();
    }
  };

  updateMapSize();

  function updateMapSize() {
    if (map) {
      map?.updateSize();
    }
  }

  function resetMap() {
    if (map) {
      initializeData();
      map.getView().fit(ward.getSource().getExtent());
    }
  }

  return (
    <div ref={mapRef} className="gis">
      <div className="map">
        <div
          className="map-element"
          style={{
            height: "100%",
            width: "100%",
          }}
          ref={mapElement}
        ></div>
        <div className="download">
          <div>
            <a
              onClick={() => {
                saveData();
              }}
              role="button"
            >
              <i className="fa fa-download"></i>Data
            </a>
          </div>
          <div>
            <a
              onClick={() => {
                saveMap();
              }}
              role="button"
            >
              <i className="fa fa-download"></i>Map
            </a>
          </div>
        </div>

        <div className="visualization">
          <Visualization
            data={[
              "Normal",
              "Gender",
              "Value Chain",
              "Age Group",
              "Farming Type",
              "Sub County",
              "Ward",
            ]}
            setvisualization={setvisualization}
            active={visualization}
          />
        </div>

        {/* <LegendRegular /> */}

        {popup && <Popup data={popup} setPopup={setPopup} />}
        {loading && <RippleLoading />}
        {waveLoading && <WaveLoading />}
        {isLoading && <RippleLoading />}
        <div
          onClick={() => {
            setBaseSelector(true);
          }}
          className="base_selector"
        >
          <i className="fa fa-map"></i>
        </div>

        {baseSelector && (
          <Basemaps
            setBaseSelector={setBaseSelector}
            basemap={basemap}
            selected={selected}
            setSelected={setSelected}
          />
        )}
        <RightPanel
          map={map}
          setData={setData}
          setIsLoading={setIsLoading}
          setExtent={setExtent}
          setFilter={setFilter}
          filter={filter}
          resetMap={resetMap}
          showing={props.showing}
          removeByTitle={removeByTitle}
        />
      </div>
    </div>
  );
}


const Visualization = (props) => {
  return (
    <select
      onChange={(e) => {
        props.setvisualization(e.target.value);
      }}
    >
      {props.data.map((item, index) => {
        return (
          <option value={item} key={index}>
            {item}
          </option>
        );
      })}
    </select>
  );
};
